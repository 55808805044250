import React from 'react';

import { useData } from 'lib/hooks';

import { ServiceElements } from './elements';

const { Content, Container, Title, Description, ImgWrapper, LinkButton, Links } = ServiceElements;

interface ServiceProps {
  title: string;
  description: string;
  links: string[];
  photo: string;
}

export const Service: React.FC<ServiceProps> = ({ title, description, links, photo }) => {
  const { offer } = useData();

  return (
    <Container>
      <ImgWrapper>
        <img src={photo} alt={`${title} - Zdjęcie`} />
      </ImgWrapper>
      <Content>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <Links>
          {links.map((link) => {
            const match = offer.find((o) => o.slug === link);
            return (
              <LinkButton key={match.slug} href={`/oferta/${match?.slug}/`}>
                {match?.title}
              </LinkButton>
            );
          })}
        </Links>
      </Content>
    </Container>
  );
};
