import { Link } from 'gatsby';
import React from 'react';

import photo1 from 'images/home/about/1.jpg';
import photo2 from 'images/home/about/2.jpg';
import photo3 from 'images/home/about/3.jpg';

import { ProductOverview } from './components/ProductOverview';
import { Service } from './components/Service';
import { AboutElements } from './elements';

const { Container, Label, OverviewWrapper } = AboutElements;
const DATA = [
  {
    id: '1',
    title: 'Tabliczki znamionowe, firmowe i informacyjne.',
    photo: photo2,
    description:
      'Naszą specjalizacją jest wytwarzanie wyrobów z metalu i tworzyw sztucznych. Wypracowaliśmy wiele metod produkcji tabliczek na maszyny i urządzenia, tabliczek znamionowych czy szyldów.',
    links: ['tabliczki-znamionowe', 'panele-sterownicze', 'inne-produkty']
  },
  {
    id: '2',
    title: 'Wycinanie, grawerowanie i znakowanie laserem.',
    photo: photo3,
    description:
      'W swojej ofercie posiadamy wiele różnego rodzaju laserów - od kompaktowych do obróbki pojedynczych sztuk aż do wieloformatowych do wycinania wzorów w całym arkuszu.',
    links: ['ciecie-laserem', 'frezowanie-grawerowanie', 'znakowanie-grawerowanie-laserem']
  },
  {
    id: '3',
    title: 'Wyroby z tworzywa sztucznego, naklejki.',
    photo: photo1,
    description:
      'Oprócz szerokiej gammy metalu w swoim asortymencie posiadamy także tworzywa PCV, naklejki a także laminat grawerski. Przy obróbce wykorzystujemy znakowanie laserowe oraz sitodruk.',
    links: ['etykiety-wywieszki', 'naklejki']
  }
];

export const About: React.FC = () => (
  <Container>
    {DATA.map((service) => (
      <Service
        key={service.id}
        title={service.title}
        description={service.description}
        links={service.links}
        photo={service.photo}
      />
    ))}
    <Label>
      Wyślij nam zapytanie, na podstawie którego indywidualnie zaprojektujemy końcowy produkt. Zapraszamy do{' '}
      <Link to="/kontakt/">kontaktu</Link> i poznania <Link to="/oferta/">pełnej oferty</Link>.
    </Label>
    <OverviewWrapper>
      <ProductOverview />
    </OverviewWrapper>
  </Container>
);
