import styled, { css } from 'styled-components';

const Container = styled.section(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    overflow: visible;
    padding: 2vmax;
    position: relative;
    width: 100vw;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      padding: 0 10vmax;
    }
  `
);

const Label = styled.div(
  ({ theme }) => css`
    ${theme.font(18, 'BOLD')};
    background: ${theme.color.gray[300]};
    color: ${theme.color.blue[200]};
    margin-top: ${theme.spacing.big};
    padding: ${theme.spacing.bigger};

    > a {
      color: ${theme.color.blue[400]};

      &:hover {
        text-decoration: underline;
      }
    }
  `
);

const OverviewWrapper = styled.div(
  ({ theme }) => css`
    align-self: center;
    width: 100%;

    ${theme.onScreen('PRE_DESKTOP')} {
      width: 75%;
    }
  `
);

export const AboutElements = {
  Container,
  Label,
  OverviewWrapper
};
