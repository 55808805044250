import React from 'react';

import { HeadingElements } from './elements';

const { Container, Subtext, Button, Content, Logo } = HeadingElements;

export const Heading: React.FC = () => {
  return (
    <Container>
      <Content>
        <Logo invert />
        <Subtext>Produkcja tabliczek znamionowych i firmowych na metalu i tworzywach sztucznych. </Subtext>
        <Button reverse primary={false} href="/oferta/">
          Pełna oferta
        </Button>
      </Content>
    </Container>
  );
};
