import React from 'react';

import Layout from 'layouts';
import { SEO } from 'SEO';

import { About } from './components/About';
import { Home } from './components/Home';

export const IndexPage: React.FC = () => (
  <Layout>
    <SEO title="Strona glowna" />
    <Home />
    <About />
  </Layout>
);
