import React from 'react';

import { Offer } from 'database';
import { useData } from 'lib/hooks';

import { HomeOfferElements } from './elements';

const { Container, ItemContainer, ItemTitleLink, DetailsLink, ItemIcon } = HomeOfferElements;

const ICON_MAP = {
  'tabliczki-znamionowe': (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="96" height="96" rx="2" stroke="white" strokeWidth="4" />
      <circle cx="14" cy="14" r="4" fill="white" />
      <circle cx="87" cy="14" r="4" fill="white" />
      <circle cx="14" cy="87" r="4" fill="white" />
      <circle cx="87" cy="87" r="4" fill="white" />
      <rect x="27" y="30" width="47" height="8" rx="2" fill="white" />
      <rect x="43" y="46" width="31" height="8" rx="2" fill="white" />
      <rect x="27" y="46" width="11" height="8" rx="2" fill="white" />
      <rect x="26" y="62" width="48" height="8" rx="2" fill="white" />
    </svg>
  ),
  'panele-sterownicze': (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="96" height="96" rx="2" stroke="white" strokeWidth="4" />
      <circle cx="26" cy="41" r="5" stroke="white" strokeWidth="4" />
      <rect x="24" y="16" width="4" height="20" rx="2" fill="white" />
      <rect x="24" y="54" width="4" height="31" rx="2" fill="white" />
      <circle cx="50" cy="49" r="5" stroke="white" strokeWidth="4" />
      <rect x="48" y="16" width="4" height="20" rx="2" fill="white" />
      <rect x="48" y="54" width="4" height="31" rx="2" fill="white" />
      <circle cx="74" cy="23" r="5" stroke="white" strokeWidth="4" />
      <rect x="72" y="28" width="4" height="57" rx="2" fill="white" />
    </svg>
  ),
  'ciecie-laserem': (
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="96" height="96" rx="2" stroke="white" strokeWidth="4" strokeDasharray="12 12" />
      <path
        d="M40 65C40 51.1929 51.1929 40 65 40H96C98.2091 40 100 41.7909 100 44V96C100 98.2091 98.2091 100 96 100H44C41.7909 100 40 98.2091 40 96V65Z"
        fill="white"
      />
    </svg>
  )
};

const Item: React.FC<{ item: Offer.Data }> = ({ item }) => (
  <ItemContainer round={0} depth={0}>
    <ItemIcon>{ICON_MAP[item.slug]}</ItemIcon>
    <ItemTitleLink>{item.title}</ItemTitleLink>
    <DetailsLink to={`/oferta/${item.slug}/`}>Zobacz ofertę</DetailsLink>
  </ItemContainer>
);

export const HomeOffer: React.FC = () => {
  const { offer } = useData();
  const display = offer.filter((o) => o.isMain);

  return (
    <Container>
      {display.map((item) => (
        <Item key={`home-offer-${item.id}`} item={item} />
      ))}
    </Container>
  );
};
