import { Link } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';

interface ButtonProps {
  href?: string;
  className?: string;
}

interface StyledButtonProps {
  name?: string;
  onClick?: () => void;
  reverse?: boolean;
  primary?: boolean;
  round?: number;
  small?: boolean;
}

const StyledButton = styled.button<StyledButtonProps>(
  ({ theme, ...props }) => css`
    align-items: center;
    ${props.primary
      ? css`
          background: ${props.reverse ? 'transparent' : theme.color.blue[200]};
          border: 2px solid ${theme.color.blue[200]};
          color: ${props.reverse ? theme.color.blue[200] : theme.color.white};
        `
      : css`
          background: ${props.reverse ? 'transparent' : theme.color.white};
          border: 2px solid ${theme.color.white};
          color: ${props.reverse ? theme.color.white : theme.color.blue[200]};
        `};

    border-radius: ${props.round !== undefined ? `${props.round}px` : '50px'};
    display: flex;
    font-size: ${props.small ? '0.875rem' : '1rem'};
    font-weight: 600;
    justify-content: center;
    min-width: 170px;
    outline: none;
    overflow: hidden;
    padding: ${props.small ? theme.spacing.mid : theme.spacing.big};
    position: relative;
    text-decoration: none;
    width: 220px;

    &::before {
      background: ${theme.color.blue[300]};
      border-radius: 50px;
      content: '';
      position: absolute;
      height: 150%;
      opacity: 0.2;
      transform: translateX(-150%);
      transition: transform 0.3s ease;
      width: 150%;
    }

    &:active {
      &::before {
        opacity: 0.5;
      }
    }

    &:hover {
      cursor: pointer;

      &::before {
        transform: translateX(0);
        transition: transform 0.3s ease;
      }
    }
  `
);

/* eslint-disable react/jsx-props-no-spreading */
export const Button: React.FC<ButtonProps & StyledButtonProps> = ({ href, className, name, ...props }) =>
  href ? (
    <StyledButton as={Link} to={href} className={className} {...props} />
  ) : (
    <StyledButton {...props} name={name} className={className} />
  );

export const OutlinedButton = styled(Button)(
  ({ theme }) => css`
    ${theme.font(14, 'SEMIBOLD')};

    background: transparent;
    border: 1px solid currentColor;
    border-radius: ${theme.radius.main};
    color: ${theme.color.blue[400]};
    height: auto;
    padding: ${theme.spacing.mid};
    width: auto;
  `
);
