import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { PoseGroup } from 'react-pose';

import { Product } from 'database';
import { useData, getSharpImage, useSearchQueryReader } from 'lib/hooks';

import { Icon } from '../../../../../../../components/Icon';
import { ControlButton } from './components/ControlButton';
import { Indicators } from './components/Indicators';
import { GalleryElements } from './elements';

const { Container, Content, ProductThumbnail, Meta, Title, Description } = GalleryElements;

export const Gallery: React.FC = () => {
  const { products } = useData();
  const [currentHref, setCurrentHref] = useState('/oferta/');
  const [{ activeSlide }, onQueryChange] = useSearchQueryReader<{ activeSlide: string }>({ activeSlide: '0' });

  const items = products
    .filter((product) => product.isOnMainPage)
    .map((product) => ({
      id: product.id,
      src: getSharpImage(product.images[0]).fixed,
      slug: product.slug,
      name: product.name,
      description: product.shortDescription
    }));

  const updateSlide = (handler: (v: number) => number): void => {
    const current = Number(activeSlide);
    const next = handler(current);
    onQueryChange({ activeSlide: String(next) });
  };

  const handleNextSlide = (): void => {
    updateSlide((current) => (current + 1) % items.length);
  };

  const handlePrevSlide = (): void => {
    const currentSlide = Number(activeSlide);

    if (currentSlide - 1 < 0) {
      updateSlide(() => items.length - 1);
    } else {
      updateSlide((current) => current - 1);
    }
  };

  const currentItem = items[activeSlide] ?? items[0];
  useEffect(() => {
    if (items[activeSlide] === undefined) {
      navigate('?activeSlide=0');
    }
  }, [items, activeSlide]);

  useEffect(() => {
    const onSlideChange = (product: Product.Data): void => {
      setCurrentHref(`/oferta/${product.categories[0]}/${product.slug}`);
    };

    const product = products.find((p) => p.id === currentItem.id);
    onSlideChange(product);
  }, [currentItem, products]);

  return (
    <Container>
      <Content onSwipedLeft={handleNextSlide} onSwipedRight={handlePrevSlide}>
        <Meta>
          <Title>{currentItem.name}</Title>
          <Description>{currentItem.description}</Description>
        </Meta>
        <PoseGroup>
          {currentItem !== undefined ? (
            <ProductThumbnail
              onClick={() => navigate(currentHref)}
              key={currentItem.id}
              src={currentItem.src}
              alt={currentItem.slug}
            />
          ) : null}
        </PoseGroup>
        <ControlButton type="prev" name="Previous element" onClick={handlePrevSlide}>
          <Icon name="arrow-ios-back" />
        </ControlButton>
        <ControlButton type="next" name="Next element" onClick={handleNextSlide}>
          <Icon name="arrow-ios-forward" />
        </ControlButton>
      </Content>
      <Indicators active={currentItem.id} total={items.map(({ id }) => id)} />
    </Container>
  );
};
