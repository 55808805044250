import React from 'react';
import { PoseGroup } from 'react-pose';

import { IndicatorsElements } from './elements';

const { Container, Icon } = IndicatorsElements;

export const Indicators: React.FC<{ active: string; total: string[] }> = ({ active, total }) => (
  <Container>
    <PoseGroup>
      {total.map((el) => (
        <Icon key={el} active={el === active} />
      ))}
    </PoseGroup>
  </Container>
);
