import React from 'react';

import { ProductOverviewElements } from './elements';
import { Gallery } from './Gallery';

const { Container } = ProductOverviewElements;

export const ProductOverview: React.FC = () => (
  <Container>
    <Gallery />
  </Container>
);
