import React from 'react';

import { Carousel } from './components/Carousel';
import { Heading } from './components/Heading';
import { HomeOffer } from './components/HomeOffer';
import { HomeElements } from './elements';

const { Container } = HomeElements;

export const Home: React.FC = () => (
  <Container>
    <Heading />
    <HomeOffer />
    <Carousel />
  </Container>
);
