import styled, { css } from 'styled-components';

import { Button } from 'components/Button';

export const Content = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      height: 80%;
      margin-right: 5vmax;
      padding: 1vmax 0;
      width: 60%;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      height: 70%;
      width: 50%;
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      height: 60%;
      width: 43%;
    }
  `
);

const ImgWrapper = styled.div(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      background: ${theme.color.white};
      display: block;
      height: 70%;
      overflow: hidden;
      width: 60%;

      img {
        filter: contrast(1) brightness(1.4);
        width: 100%;
      }
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      height: 70%;
      width: 50%;
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      height: 60%;
      width: 53%;
    }
  `
);

export const Title = styled.h2(
  ({ theme }) => css`
    ${theme.font(22, 'BOLD')};
    color: ${theme.color.blue[100]};

    ${theme.onScreen('SMALL_DESKTOP')} {
      ${theme.font(34, 'BOLD')};
    }

    ${theme.onScreen('PRE_DESKTOP')} {
      ${theme.font(42, 'BOLD')};
    }
  `
);

export const Description = styled.p(
  ({ theme }) => css`
    ${theme.font(16, 'SEMIBOLD')};
    color: ${theme.color.gray[200]};
    margin: 3vmax 0;

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      ${theme.font(18, 'BOLD')};
      line-height: 1.7;
    }

    ${theme.onScreen('PRE_DESKTOP')} {
      ${theme.font(22, 'BOLD')};
    }
  `
);

const Container = styled.div(
  ({ theme }) => css`
    background: ${theme.color.gray[300]};
    display: flex;
    margin-bottom: ${theme.spacing.huge};
    padding: ${theme.spacing.big};

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      align-items: center;
      background: none;
      height: 50vh;
      justify-content: center;
      flex-direction: row-reverse;
      margin-bottom: 0;
      padding: 0;
      border-bottom: 2px dashed ${theme.color.gray[300]};

      &:nth-child(2n) {
        flex-direction: row;

        ${ImgWrapper} {
          background: ${theme.color.blue[400]};
        }

        ${Content} {
          margin-right: 0;
          margin-left: 5vmax;
        }
      }
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      height: 70vh;
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      height: 85vh;
    }
  `
);

const Links = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const LinkButton = styled(Button)(
  ({ theme }) => css`
    align-self: center;
    background: none;
    border-color: ${theme.color.blue[400]};
    height: 50px;
    justify-self: center;
    margin-bottom: ${theme.spacing.mid};
    margin-right: ${theme.spacing.mid};
    width: auto;
  `
);

export const ServiceElements = {
  Content,
  Container,
  Title,
  Description,
  ImgWrapper,
  LinkButton,
  Links
};
