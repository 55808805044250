import posed from 'react-pose';
import { Swipeable } from 'react-swipeable';
import styled, { css } from 'styled-components';

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const Content = styled(Swipeable)(
  ({ theme }) => css`
    align-items: center;
    background: radial-gradient(${theme.color.blue[600]}, ${theme.color.blue[200]});
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;

    img {
      cursor: pointer;
      filter: opacity(0.7);
      height: 350px;
      object-fit: cover;
      transition: filter 0.2s ease;
      width: 100%;

      &:hover {
        filter: opacity(0.5);
      }
    }

    ${theme.onScreen('TABLET')} {
      width: 100%;
      img {
        height: 440px;
      }
    }

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      width: 70%;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      width: 100%;

      img {
        height: 500px;
      }
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      img {
        height: 660px;
      }
    }
  `
);

const Meta = styled.div(
  ({ theme }) => css`
    left: ${theme.spacing.bigger};
    position: absolute;
    top: ${theme.spacing.bigger};
    z-index: 1;
  `
);

const Title = styled.h3(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('TABLET')} {
      color: ${theme.color.white};
      ${theme.font(36, 'BOLD')};
      display: block;
      text-transform: uppercase;
    }
  `
);

const Description = styled.h3(
  ({ theme }) => css`
    display: none;
    ${theme.onScreen('TABLET')} {
      color: ${theme.color.white};
      ${theme.font(18, 'SEMIBOLD')};
      display: block;
    }
  `
);

const ProductThumbnail = posed.img({
  enter: { opacity: 1, delay: 100 },
  exit: { opacity: 0.5 }
});

export const GalleryElements = {
  Container,
  Content,
  ProductThumbnail,
  Meta,
  Title,
  Description
};
