import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: ${theme.spacing.bigger};
    width: 100%;
  `
);

const Icon = styled.div<{ active: boolean }>(
  ({ theme, active }) => css`
    background: ${active ? theme.color.blue[600] : 'transparent'};
    border: 2px solid ${theme.color.blue[600]};
    border-radius: 100%;
    height: 20px;
    margin: 0 10px;
    width: 20px;
  `
);

export const IndicatorsElements = { Container, Icon };
