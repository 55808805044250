import styled, { css } from 'styled-components';

import { Button as ButtonBase } from 'components/Button';
import { Logo as LogoBase } from 'components/Navigation/components/Logo';

const Container = styled.div(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      background: rgba(255, 255, 255, 0.8);
      display: flex;
      grid-area: heading;
      height: 100%;
      justify-content: center;
      width: 100%;
      grid-column: 1;
      z-index: 2;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      grid-column: 2;
    }
  `
);

const Content = styled.div(
  ({ theme }) => css`
    align-items: center;
    align-self: center;
    display: flex;
    flex-direction: column;
    height: 40vh;
    justify-content: space-evenly;
    width: 80%;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      align-items: flex-start;
      width: 300px;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      width: 430px;
    }

    ${theme.onScreen('PRE_DESKTOP')} {
      width: 490px;
    }
  `
);

const Subtext = styled.h1(
  ({ theme }) => css`
    ${theme.font(16, 'SEMIBOLD')};
    color: ${theme.color.gray[200]};

    ${theme.onScreen('SMALL_DESKTOP')} {
      ${theme.font(18, 'SEMIBOLD')};
    }

    ${theme.onScreen('PRE_DESKTOP')} {
      ${theme.font(20, 'SEMIBOLD')};
    }
  `
);

const Button = styled(ButtonBase)(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      border-color: ${theme.color.blue[400]};
      color: ${theme.color.blue[100]};
      display: flex;
      margin-top: ${theme.spacing.huge};
    }
  `
);

const Logo = styled(LogoBase)(
  ({ theme }) => css`
    width: 200px;

    ${theme.onScreen('SMALL_DESKTOP')} {
      width: 260px;
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      width: 300px;
    }

    ${theme.onScreen('PRE_DESKTOP')} {
      width: 300px;
    }
  `
);

export const HeadingElements = {
  Container,
  Subtext,
  Button,
  Content,
  Logo
};
