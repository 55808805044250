import React, { useEffect, useRef, useState } from 'react';

import photo1 from 'images/home/carousel/photo1.jpg';
import photo2 from 'images/home/carousel/photo2.jpg';
import photo3 from 'images/home/carousel/photo3.jpg';
import photo4 from 'images/home/carousel/photo4.jpg';
import photo5 from 'images/home/carousel/photo5.jpg';

import { CarouselElements } from './elements';

const { Container, Img, LockLayer } = CarouselElements;

const useInterval = (callback: () => void, duration = 2000): void => {
  const interval = useRef(null);

  useEffect(() => {
    interval.current = setInterval(callback, duration);
    return () => clearInterval(interval.current);
  }, [duration, callback]);
};

export const Carousel: React.FC = () => {
  const [currentPhoto, setCurrentPhoto] = useState<number>(0);
  const photos = [photo1, photo2, photo3, photo5, photo4];
  const changePhoto = (): void => setCurrentPhoto((prev) => (prev + 1 >= photos.length ? 0 : prev + 1));
  useInterval(changePhoto);

  return (
    <Container>
      <LockLayer />
      {photos.map((photo, index) => (
        <Img
          key={JSON.stringify(photo)}
          src={photo}
          active={index === currentPhoto}
          alt={`Obraz slidera numer ${index}`}
        />
      ))}
    </Container>
  );
};
