import styled, { css } from 'styled-components';

import { Button as ButtonBase } from 'components/Button';

const BUTTON_SIZE = 65;

export const Button = styled(ButtonBase)<{ type: string }>(
  ({ theme, type }) => css`
    display: none;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      background: ${theme.color.blue[400]};
      border-color: ${theme.color.blue[400]};
      box-shadow: ${theme.shadow.depth3};
      display: block;
      height: ${BUTTON_SIZE}px;
      min-width: 0;

      ${type === 'prev' && `left: -${BUTTON_SIZE / 2}px`};
      ${type === 'next' && `right: -${BUTTON_SIZE / 2}px`};
      position: absolute;
      top: calc(50% - ${BUTTON_SIZE / 2}px);
      width: ${BUTTON_SIZE}px;

      svg {
        fill: ${theme.color.white};
      }
    }
  `
);

export const ControlButtonElements = {
  Button
};
