import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      display: block;
      grid-column: 1/-1;
      grid-row: 1;
      height: 100%;
      position: relative;
      z-index: 1;
    }
  `
);

const Img = styled.img<{ active: boolean }>(
  ({ active }) => css`
    height: 100%;
    opacity: 0;
    position: absolute;
    transition: opacity 0.3s ease;
    width: 100%;
    z-index: 0;

    ${active &&
    css`
      opacity: 1;
      z-index: 1.5;
    `}
  `
);

const LockLayer = styled.div`
  background: transparent;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export const CarouselElements = {
  Container,
  Img,
  LockLayer
};
