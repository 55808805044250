import { Link } from 'gatsby';
import styled, { css } from 'styled-components';

import { Paper } from 'components/Paper';

const Container = styled.div(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('TABLET')} {
      grid-area: presentation;
      align-self: center;
      display: flex;
      grid-column: 1;
      grid-row: 1;
      justify-self: center;
      z-index: 2;
      margin-top: 20px;
      width: 100%;
      padding: 0 10px;
    }

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      padding: 0;
      grid-column: 2;
      flex-direction: column;
      margin-top: 0;
      width: auto;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      grid-column: 3;
    }
  `
);

const ItemContainer = styled(Paper)(
  ({ theme }) => css`
    background: ${theme.color.blue[400]};
    display: grid;
    grid-column-gap: ${theme.spacing.big};
    grid-template-columns: 40px 1fr;
    grid-template-rows: 20px 20px;
    margin: 0 ${theme.spacing.mid};
    padding: ${theme.spacing.big};
    width: 100%;

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      opacity: 0.9;
      margin: 0;
      margin-bottom: ${theme.spacing.big};
      transition: background 0.2s ease;
      width: auto;
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      margin-bottom: ${theme.spacing.huge};
      padding: ${theme.spacing.bigger};
    }
  `
);

const ItemTitleLink = styled.h2(
  ({ theme }) => css`
    ${theme.font(16, 'SEMIBOLD')};
    color: ${theme.color.white};
    grid-column: 2;
    grid-row: 1;
    line-height: 1.2;
    margin-bottom: ${theme.spacing.small};

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      ${theme.font(18, 'SEMIBOLD')};
      margin-bottom: ${theme.spacing.small};
    }

    ${theme.onScreen('PRE_DESKTOP')} {
      ${theme.font(20, 'SEMIBOLD')};
    }
  `
);

const DetailsLink = styled(Link)(
  ({ theme }) => css`
    ${theme.font(14, 'SEMIBOLD')};
    color: ${theme.color.blue[100]};
    grid-column: 2;
    grid-row: 2;
    margin-top: auto;

    &:hover {
      text-decoration: underline;
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      ${theme.font(15, 'SEMIBOLD')};
    }
  `
);

const ItemIcon = styled.div`
  grid-column: 1;
  grid-row: span 2;
  svg {
    height: 40px;
    width: 40px;
  }
`;

export const HomeOfferElements = {
  Container,
  ItemContainer,
  ItemTitleLink,
  DetailsLink,
  ItemIcon
};
