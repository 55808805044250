import styled, { css } from 'styled-components';

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing.big};
    padding: ${theme.spacing.big} 0;
    position: relative;
    width: 100%;

    &:before,
    &:after {
      display: none;
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      margin-top: 40px;
      padding: 40px 0;

      &:before,
      &:after {
        background: ${theme.color.blue[400]};
        content: '';
        display: block;
        height: 6px;
        position: absolute;
        top: 0;
        width: 45px;
      }

      &:after {
        background: ${theme.color.gray[200]};

        bottom: 0;
        right: 0;
        top: unset;
      }
    }

    ${theme.onScreen('MEDIUM_DESKTOP')} {
      margin-top: 120px;
      padding: 120px 0;
    }
  `
);

export const ProductOverviewElements = {
  Container
};
