import styled, { css } from 'styled-components';

const Container = styled.section(
  ({ theme }) => css`
    display: none;

    ${theme.onScreen('TABLET')} {
      align-items: center;
      background: ${theme.color.white};
      display: grid;
      grid-template-areas: 'heading' 'presentation';
      grid-template-columns: 1fr;
      grid-template-rows: 1fr auto;
      height: auto;
      overflow: hidden;
      width: 100%;
    }

    ${theme.onScreen('TABLET_LANDSCAPE')} {
      grid-template-columns: 0.5fr 0.5fr;
      grid-template-rows: 1fr;

      height: calc(100vh - ${theme.size.menuHeight});
    }

    ${theme.onScreen('SMALL_DESKTOP')} {
      flex-direction: row;
      grid-template-areas: '. heading presentation';
      grid-template-columns: 0.1fr 0.4fr 0.5fr;
      justify-content: space-between;
      padding: 0;
    }
  `
);

export const HomeElements = {
  Container
};
