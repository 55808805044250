import React from 'react';

import { ControlButtonElements } from './elements';

const { Button } = ControlButtonElements;

interface ControlButtonProps {
  name: string;
  type: 'next' | 'prev';
  onClick: () => void;
}

export const ControlButton: React.FC<ControlButtonProps> = ({ name, children, type, onClick }) => (
  <Button onClick={onClick} primary type={type} name={name}>
    {children}
  </Button>
);
